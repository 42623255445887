
import React from "react";
import styled, { keyframes } from "styled-components"
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import {  Title, Section, Box, Text, Span } from "../../components/Core";
import { breakpoints } from "../../utils";
import imgC1 from "../../assets/image/jpeg/fig1.png";
import imgC2 from "../../assets/image/jpeg/fig2.png";
import imgC3 from "../../assets/image/jpeg/fig3.png";
import imgC4 from "../../assets/image/jpeg/fig4.png";
import imgC5 from "../../assets/image/jpeg/fig41.png";
import imgC7 from "../../assets/image/jpeg/fig5.png";
import imgC8 from "../../assets/image/jpeg/fig6.png";
import imgC9 from "../../assets/image/jpeg/fig7.png";


const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`


const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const LittleText = styled.h3`
  font-size: 10px;
`;

const arrowAnimation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`

const Arrow = styled.i`
  margin-left: 10px;
  font-size: 1em;
  animation: ${arrowAnimation} 1.5s infinite;
  color: #00aec7;

  @media screen and (max-width: 767px) {
    font-size: 0.8em;
  }
`

const SwipeTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding-bottom: 0px;
  color: #1a1a1a;
  font-weight: 300;
  margin-top: 1em;

  @media screen and (max-width: 767px) {
    font-size: 0.8em;
  }
`

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: rgb(171, 142, 102, 0.3);
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: rgb(171, 142, 102);
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }

  .slick-slide {
    transition: transform 0.3s ease, filter 0.3s ease;
    padding: 0 10px; /* Add padding between slides */
    &:not(.slick-center) {
      transform: scale(1);
      @media screen and (max-width: 767px) {
        filter: grayscale(100%);
        transform: scale(0.8);
      }
    }
  }

  .slick-center {
    transform: scale(1.2);
  }
`

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    mx="3px"
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
      margin: 0 5px; /* Add margin to create gaps between items */
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-flex justify-content-between`}>
      <Box className="d-flex justify-content-center align-items-start" mr={0}>
        <img src={image} alt="Non Surgical Nose Job" className="img-fluid" />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
)

const SkinpenGallery = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: false,

    centerMode: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 2,
          infinite: true,
          centerMode: false,
          autoplay: false,
          slidesToScroll: 1,
          centerMode: false,
         
        },
      },
    ],
  }

  return (
    <>
   <Separator />
  
   
      <Section py={4} bg="#f7f7fb">
        <Container className="pt-3 pb-3">
          <Row className=" pb-4">
            <Col md="9" lg="12" className="">
      
                   <Iwrap>


<SecondText as="h2">Why Choose our Skin Analyser Service</SecondText> <br /> <br />
</Iwrap>
     <Text className="col-lg-9">
     Understanding what is happening beneath the surface of your skin is crucial for effective treatments and long-term results. Everyone’s skin is unique, and treating it effectively starts with a deeper understanding of its condition. Our advanced skin analysis service helps uncover the hidden stories your skin tells, allowing us to create personalized skincare plans tailored specifically to your needs.
        </Text> <br /> <br /> <br />

<SecondText as="h2">What Does the Skin Analysis Reveal?</SecondText> <br /> <br />

<Text className="col-lg-9">Our machine provides a comprehensive analysis that includes: <br />

    <br />
    <ul>
    <li>Skin Texture: Identifying areas with unevenness, fine lines, and wrinkles.</li>
    <li>Pores: Mapping out pore size and density for targeted treatments.</li>
    <li>UV Damage: Detecting sun damage to prevent premature aging.</li>
    <li>Pigmentation: Highlighting areas of discoloration, melasma, and age spots.</li>
    <li>Redness and Sensitivity: Spotting inflammation and underlying sensitivities.</li>
    <li>Porphyrins: Detecting the presence of porphyrins, which are indicators of bacteria residing on or within your skin, helping to address concerns like acne and inflammation effectively.</li>
</ul>

<br />
</Text>

            
             
            </Col>
            </ Row>

          
            <Row>
         
            <Col lg="11"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
            data-aos-delay="500"
            >
              <SliderStyled {...slickSettings}>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                  
                >
                  <ContentCard
                    image={imgC1}
                  >
                  <LittleText>Figure 1 Wrinkles</LittleText>
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC2}
                  >
                  <LittleText>Figure 2 Sensitivity</LittleText>
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC3}
                  >
                 <LittleText>Figure 3 Pores</LittleText>
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC4}
                  >
                 <LittleText> Figure 4 Hyperpigmentation / Pigmentation</LittleText>
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC5}
                  >
                 <LittleText> Figure 4 Hyperpigmentation / Pigmentation</LittleText>
                  </ContentCard>
                </Box>
               
             
              
               
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC7}
                  >
                 <LittleText>  Figure 5 Porphyrins</LittleText>
                  </ContentCard>
                </Box>

                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC8}
                  >
                <LittleText>   Figure 6 Heat Map</LittleText>
                  </ContentCard>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <ContentCard
                   
                    image={imgC9}
                  >
                 <LittleText>  Figure 7 Woods Lamp</LittleText>
                  </ContentCard>
                </Box>
    
            
              </SliderStyled>
            </Col>
          </Row>
          <SwipeTextContainer>
            Swipe
            <Arrow className="fas fa-arrow-right" />
          </SwipeTextContainer>
        </Container>
      </Section>
     <SeparatorEnd />
    </>
  );
};

export default SkinpenGallery;




