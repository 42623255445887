import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/needling.jpg";
import Link from 'gatsby-link';

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const MicroContentPage = ({setIsModalOpen}) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-3 pb-3">

          <Row className="pb-4">
              <Col>
                <Iwrap>


<Itext as="h2"><span color="00aec7">Microneedling</span></Itext>
</Iwrap>
      <SecondText>
      What is Skinpen® Microneedling treatment?

      </SecondText>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4  mb-lg-0">
            <div
              className=""
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <img src={imgMobile} alt="What is Microneedling" className="rounded shadow" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">

              <Text>

                Bring out your inner beauty, natural skin rejuvenation and healing power with Skinpen® Precision®
                Microneedling – the most technologically advanced and the only FDA approved, Class II Medical skin
                remodelling device in the world that is evidence-based with visible results seen after just one
                treatment. <br /> <br />

                Microneedling is clinically proven to be effective for women and men of all skin types and tones. A
                natural and effective treatment - nothing injected, no chemicals or heat applied. Microneedling works by
                creating microchannels, targeting precise depth of the skin stimulating your skins natural wound healing
                process, producing elastin and collagen to rejuvenate your skin from the inside out.<br /> <br />

                The treatment also boosts skin turnover, improve skin elasticity, firmness and regulate the distribution
                of skin pigments of the skin. So, whether you are looking to reduce the appearance of <Link
                to="/forehead-lines">fine lines</Link>, <Link to="/acne-active-acne">acne scarring</Link> or <Link
                to="/hyperpigmentation">hyper pigmentation</Link>, or simply looking for a refreshed glow and preventing
                signs of aging, Skinpen® Precision can help everyone achieve their skin goals.<br /> <br />

                At Dermamina London, we like to go the extra mile to create a tailored and personalised treatment
                protocol that will maximise your treatment results. All appointments begin with a thorough consultation;
                we will go through your concerns together and carefully assess your skin accordingly. Then we will
                thoroughly explain the procedure, risks and benefits prior to treatment.


              </Text> <br />

              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active"
              >
                <Bookbutton>
                  Book Appointment
                </Bookbutton>
              </div>

            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default MicroContentPage;
