import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";
import NoseContent from '../sections/analyser/anacontent.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Gallery from "../sections/analyser/analysergallery.js";
import Testimonial from "../sections/index/indexreviews.js";
import Extra from "../sections/micro/mextra.js";
import Mtwo from "../sections/micro/mtwo.js";
import Microfaq from "../sections/skinpen/skinpenfaq.js"
import Award from "../components/NewToxane.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/skin-analyser.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";
import PriceBanner from '../components/pricebanner.js';
import PopupForm from "../components/PopupForm/PopupForm"

const MicroPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
  <>
 <Head title="Skin Analyser" image="/images/dermamina-home.jpg"  description="Go Beyond the Surface: Introducing Our Skin Analyser" keywords="Skin Analyser Consultation"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},


{'@type': 'ListItem', 
position:2,
name:'Skinpen® Microneedling',
item:'https://www.dermamina.com/Skinpen-microneedling',
},

]
}}
</JsonLd>


<JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [
          {
            "@type": "Question",
            "name": "What is Microneedling?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "A: Micro-needling is a minimally invasive aesthetic procedure in which tiny, sterile needles are used to form micro-channels in the skin. This procedure enhances the body's natural healing response, resulting in increased collagen and elastin synthesis, enhanced skin texture, and a reduction in the visibility of scars, wrinkles, and other skin flaws."
            }
          },
          {
            "@type": "Question",
            "name": "How long does a microneedling session last?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The duration of a micro-needling treatment varies depending on the area being treated, however most sessions run 30-60 minutes."
            }
          },
          {
            "@type": "Question",
            "name": "Is microneedling appropriate for all skin types?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, microneedling is suitable for all skin types and colours. Your skincare specialist, on the other hand, will assess your skin and medical history to determine if micro-needling is the best treatment for you."
            }
          },
          {
            "@type": "Question",
            "name": "Does microneedling cause pain?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Although microneedling is generally safe, some individuals may experience slight discomfort during the process. To alleviate any discomfort, a numbing cream can be given to the treatment region."
            }
          }
        ]
      }}
    </JsonLd>

    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "AggregateRating",
        "ratingValue": "4.9",
        "reviewCount": "603"
      }}
    </JsonLd>

 


    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "Service",
        "serviceType": "Microneedling",
        "provider": {
          "@type": "Organization",
          "name": "Dermamina",
          "url": "https://www.dermamina.com"
        },
        "areaServed": {
          "@type": "Place",
          "name": "London"
        },
        "description": "Microneedling Treatment London",
        "offers": {
          "@type": "Offer",
          "url": "https://www.dermamina.com/skinpen-microneedling",
          "priceCurrency": "GBP",
          "price": "250",
          "eligibleRegion": {
            "@type": "Place",
            "name": "London"
          }
        }
      }}
    </JsonLd>


    <JsonLd>
      {{
        "@context": "https://schema.org",
        "@type": "ItemList",
        "itemListElement": [
          {
            "@type": "ImageObject",
            "position": 1,
            "name": "Before and After Microneedling",
            "contentUrl": "https://www.dermamina.com/static/micro7-2ad1d43d0531b3a80a2e124b18201c4b.jpg",
            "description": "Image showing hair condition before and after Microneedling treatment."
          },
          {
            "@type": "ImageObject",
            "position": 2,
            "name": "Before and After Microneedling",
            "contentUrl": "https://www.dermamina.com/static/micro3-f4a04cdddb66aa8c542521238eaaa686.jpg",
            "description": "Image showing hair condition before and after Microneedling treatment."
          }
        ]
      }}
    </JsonLd>

      <PageWrapper headerDark footerDark>
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Skin</span> <span style="color: #00aec7;">Analyser</span>'
    secondText="Go beyond the surface"
    videoAlt="Skin Analyser"
    imgVideo={imgVideo} // Specify your image URL here
    setIsModalOpen={setIsModalOpen}
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="3-6 consultation"
  bestalt="best Skin analyser"
  resultstext="Immediately"
  resultsalt="Skin analyser results"
  costdata="£100"
  costalt="skin analyser Cost"
  timedata="40 Minutes"
  timealt="skin analyser Duration Time"
  workdata="Immediately"
  workalt="skin analyser downtime work"
  paindata="None"
  painalt="skin analyser Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>

      <Gallery />
    
      <NoseContent setIsModalOpen={setIsModalOpen}/>
      <Testimonial />
      <Award /> 
      <Microfaq setIsModalOpen={setIsModalOpen}/>
      <Clinic />
      <Saveface />
      <Trend />
     </PageWrapper>
 
  </>
)}
export default MicroPage
