import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import imgMobile from "../../assets/image/jpeg/needling.jpg";
import Link from 'gatsby-link';

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 0px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const MicroContentPage = ({setIsModalOpen}) => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-3 pb-3">

          <Row className="pb-4">
              <Col>
               
      <SecondText as="h2">
     How It Works

      </SecondText>
              </Col>
          </Row>
        <Row className="">
          
          <Col lg="9" md="9">
            <Box className=" pt-lg-0">

              <Text>

              1.	Consultation: Your appointment begins with an inperson consultation with one of our expert practitioners. <br /> <br />
2.	Analysis: The painless and quick scan takes just minutes, providing a full report on your skin’s health.<br /> <br />
3.	Results: We will review the detailed analysis with you, breaking down the findings in easy-to-understand terms.<br /> <br />
4.	Personalised Plan: Based on your results, we will recommend treatments, skincare products, and lifestyle changes to achieve your skin goals.<br /> <br />



              </Text>
              
              <SecondText as="h2">Who is it for?</SecondText> <br /> <br />
              <Text>
              Our skin analysis service is suitable for everyone, whether you are starting your skincare journey, maintaining your current routine, or addressing specific concerns like acne, aging, or pigmentation.<br /> <br />
The Benefits of a Professional Skin Analysis<br /> <br />
•	Customized Solutions: Tailor-made skincare plans that work specifically for your skin type and concerns.<br /> <br />
•	Preventative Care: Early detection of potential issues for timely interventions.<br /> <br />
•	Maximised Results: Ensures every treatment and product is optimized for your skin.<br /> 

              </Text>
               <br />

              <div onClick={() => setIsModalOpen(true)}
                   activeClassName="active"
              >
                <Bookbutton>
                  Book Appointment
                </Bookbutton>
              </div>

            </Box>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default MicroContentPage;
