import React from "react";
import { Link } from "gatsby";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import one from "../assets/image/jpeg/dream-nose.png";
import two from "../assets/image/jpeg/consultation.png";
import { Section } from "./Core";

// Styled components for custom styling
const BannerContainer = styled.div`
  position: relative;
  height: 300px; /* Adjust height as needed */
  background: ${(props) =>
    props.isEven
      ? "linear-gradient(135deg, #e0e0e0, #cfcfcf)" /* Gradient for even banners */
      : "linear-gradient(135deg, #f4f4f4, #eaeaea)"}; /* Gradient for odd banners */
  overflow: hidden;
  padding: 2rem;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0; /* No gap by default on desktop */

  @media (max-width: 768px) {
    height: 200px; /* Adjust height as needed */
    margin-bottom: 20px; /* Add 20px gap on mobile */
  }
`;

const BannerImage = styled.img`
  position: absolute;
  bottom: 0; /* Stick image to the bottom */
  right: 0;
  width: 50%; /* Adjust width to fit the div */
  height: auto;
  object-fit: contain; /* Keep aspect ratio */
  transition: transform 0.5s ease;
  &:hover {
    transform: scale(1.1); /* Slight zoom on image hover */
  }
`;

const BannerContent = styled.div`
 
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; /* Ensure content fills entire height */
`;

const OfferText = styled.h2`
  font-size: 1.5rem;
  color: #00aec7;
  margin-bottom: 0.5rem;
  @media (max-width: 768px) {
    font-size: 1.2rem;
  }
`;

const AboutText = styled.p`
  font-size: 1.3rem;
  margin-bottom: 1rem;
  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const BannerButton = styled.button`
  background-color: #1a1a1a;
  color: white;
  padding: 0.75rem 1.5rem;
  border: none;
  font-size:12px;
  border-radius: 32px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start; /* Align button with text */
  margin-top: auto; /* Push button to the bottom */
  margin-bottom: 0px; /* 20px gap from the bottom */
  &:hover {
    background-color: #00aec7;
  }
`;

// Data array containing information about images, offers, and links
const Data = [
  {
    img: one,
    about: "For £450",
    offer: "Get your Dream Nose",
    link: "https://dermamina.book.app/book-now",
    class: " p-left text-start",
  },
  {
    img: two,
    about: "FREE",
    offer: "Hair & Skin Consultation",
    link: "https://dermamina.book.app/book-now",
    class: " p-left text-start",
  },
];

// Component to display individual banners
const MasterCollectionBanner = ({ img, about, offer, link, classes, isEven }) => {
  return (
    <Col md="6">
      <Link to={link}>
        <BannerContainer className={`collection-banner ${classes}`} isEven={isEven}>
          <BannerContent>
            <div>
              <OfferText>{offer}</OfferText>
              <AboutText>{about}</AboutText>
            </div>
            <BannerButton>Book Now</BannerButton>
          </BannerContent>
          <BannerImage src={img} loading="lazy" alt={about} />
        </BannerContainer>
      </Link>
    </Col>
  );
};

// Main Collection component
const Collection = ({ first }) => {
  return (
    <Section py={0}>
      <Container className="pt-5">
        <Row className="partition3">
          {first
            ? Data.slice(0, 2).map((data, i) => {
                return (
                  <MasterCollectionBanner
                    key={i}
                    img={data.img}
                    link={data.link}
                    about={data.about}
                    offer={data.offer}
                    classes={data.class}
                    isEven={i % 2 === 1} /* Check if the index is even */
                  />
                );
              })
            : Data.slice(2, 4).map((data, i) => {
                return (
                  <MasterCollectionBanner
                    key={i}
                    img={data.img}
                    link={data.link}
                    about={data.about}
                    offer={data.offer}
                    classes={data.class}
                    isEven={i % 2 === 1} /* Check if the index is even */
                  />
                );
              })}
        </Row>
      </Container>
    </Section>
  );
};

export default Collection;
